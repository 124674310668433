import React from 'react'
import Layout from '../../components/layout'

function ForgotPassword() {
  return (
    <Layout>
        <div className='section' style={{height:'500px'}}>Comming Soon</div>
    </Layout>
  )
}

export default ForgotPassword